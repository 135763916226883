/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false,
    show_nav_site_name: true
  },
  icons: {
    type: 'default',
    custom: {
      ic_arrow_drop_down: {
        viewbox: '0 96 960 960',
        content: '<path d="M480 711 240 471l56-56 184 184 184-184 56 56-240 240Z"/>'
      }
    }
  },
}
